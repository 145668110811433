<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="headline">Удалить ККТ?</v-card-title>
      <v-card-text>
        Вы уверены, что хотите удалить ККТ с регистрационным номером {{ itemToDelete?.regId }}?
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" text @click="cancelDelete">Отмена</v-btn>
        <v-btn color="orange" text @click="confirmDelete">Удалить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteDialog",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    itemToDelete: {
      type: Object,
      default: null,
    },
  },
  emits: ["update:dialog", "delete-confirmed"],
  methods: {
    confirmDelete() {
      this.$emit("delete-confirmed", this.itemToDelete.id);
      this.$emit("update:dialog", false);
    },
    cancelDelete() {
      this.$emit("update:dialog", false);
    },
  },
};
</script>
