<template>
  <v-container fluid>
    <v-container class="filters">
      <v-row justify="start" class="mb-1">
        <v-col cols="12" md="3">
          <v-text-field label="Рег. Номер или Номер ФН" v-model="fieldForFind"></v-text-field>
        </v-col>


        <v-col cols="12" md="3">
          <v-combobox label="Статус" :items="combinedStatuses" v-model="selectedStatus"></v-combobox>
        </v-col>


        <v-col outlined tile>
          <v-btn block dark color="orange" elevation="0" v-on:click="loadKKT">
            Поиск
          </v-btn>
        </v-col>


        <v-col outlined tile v-if="this.$store.getters.user.role === this.$enums.Roles.Admin">
          <v-btn @click="openDialog" block dark color="green" elevation="0">
            Создать
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <edit-dialog :value="dialog" :dialog-title="'Создать ККТ'" :kktData="kktData" @input="dialog = $event"
      @save="createKkt"></edit-dialog>
    <KktTable :loading="loading" :kkt-data="KKT" :total-rows="totalRows" @update:options="handleOptionsUpdate"
      @kkt-deleted="deregisterKkt" @edit-item="updateKkt" />
  </v-container>
</template>

<script>
import KktTable from "@/components/KKTPage/KktTable.vue";
import OverlayLoader from "@/components/UI/OverlayLoader.vue";
import axios from "axios";
import EditDialog from "@/components/KKTPage/EditDialog.vue";
import { kktStatuses } from "@/services/constants";

export default {
  name: "KKTCrud",
  components: { KktTable, OverlayLoader, EditDialog },
  data() {
    return {
      fieldForFind: null,
      selectedStatus: "Все",
      dialog: false,
      kktData: {
        regId: '',
        storageId: '',
        sbisCompanyId: '',
        status: ''
      },
      totalRows: 0,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      loading: true,
      KKT: [],
    };
  },
  computed: {
    combinedStatuses() {
      return [
        { text: "Все", value: "" },
        ...kktStatuses,
      ];
    },
  },
  async mounted() {
    await this.loadKKT(this.options.page, this.options.itemsPerPage);
  },
  watch: {
    "options.page"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadKKT(this.options.page, this.options.itemsPerPage);
      }
    },
    "options.itemsPerPage"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadKKT(this.options.page, this.options.itemsPerPage);
      }
    },
    selectedStatus() {
      this.options.page = 1;
      this.loadKKT(this.options.page, this.options.itemsPerPage);
    }
  },
  methods: {
    async loadKKT(page = 1, itemsPerPage = 15) {
      this.loading = true;
      const params = {
        skip: Number.isNaN(page - 1) || page <= 0
          ? (this.options.page - 1)
          : (page - 1),
        take: (itemsPerPage || this.options.itemsPerPage) === -1 ? this.totalRows : (itemsPerPage || this.options.itemsPerPage),
      };
      if (this.selectedStatus.value === "") {
        params.status = null;
      } else {
        params.status = this.selectedStatus.text;
      }
      params.find = this.fieldForFind ? this.fieldForFind.trim() : null;
      try {
        const response = await axios.get(this.$enums.Endpoints.Admin.Kkt.Get, { params });
        this.KKT = response.data.pageItems.map((e) => ({ ...e }));
        this.totalRows = response.data.total;
      } catch (error) {
        console.error("Ошибка при запросе на сервер:", error);
      } finally {
        this.loading = false;
      }
    },

    handleOptionsUpdate(newOptions) {
      this.options = newOptions;
      this.loadKKT(this.options.page, this.options.itemsPerPage);
    },

    async deregisterKkt(id) {
      try {
        const deleteUrl = `${this.$enums.Endpoints.Admin.Kkt.Delete}/${id}`;
        await axios.put(deleteUrl);
        await this.loadKKT(this.options.page, this.options.itemsPerPage);
      } catch (error) {
        console.error("Ошибка при удалении ККТ:", error);
      }
    },

    async updateKkt(updatedKkt) {
      const dataToSend = {
        RegId: updatedKkt.regId,
        StorageId: updatedKkt.storageId,
        Status: updatedKkt.status.text || updatedKkt.status,
        SbisCompanyId: updatedKkt.sbisLogin ? updatedKkt.sbisLogin.id : updatedKkt.sbisCompanyId,
      };
      try {
        await axios.put(
          `${this.$enums.Endpoints.Admin.Kkt.Update}/${updatedKkt.id}`, dataToSend);
        await this.loadKKT(this.options.page, this.options.itemsPerPage);
      } catch (error) {
        console.error("Ошибка при обновлении ККТ:", error.message);
      }
    },
    openDialog() {
      this.dialog = true;
    },
    async createKkt(data) {
      const dataToSend = {
        RegId: data.regId,
        StorageId: data.storageId,
        SbisCompanyId: data.sbisLogin.id,
        Status: data.status.text,
      };
      try {
        await axios.post(this.$enums.Endpoints.Admin.Kkt.Create, dataToSend);
        this.dialog = false;
        await this.loadKKT(this.options.page, this.options.itemsPerPage);
      } catch (error) {
        console.error("Ошибка при создании ККТ:", error);
      }
    }
  },
};
</script>
