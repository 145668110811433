<template>
  <v-card flat class="px-5 pb-5">
    <v-row justify="center" class="pr-15 pl-4">
      <v-col cols="12" align-self="center" class="text-h5 px-10 mb-5"> </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="8">
            <v-text-field outlined label="Номер билета без кода" v-model="numberTicket"
              style="height: 3.5rem"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-checkbox label="С ценой" v-model="isFull" color="orange"></v-checkbox>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="6">
            <v-text-field outlined label="Cбор" v-model="markup"
              :rules="[(val) => val > 0 || 'Введите число, например 10.50']" />
          </v-col>
          <v-col cols="6">
            <v-select v-model="subClass" outlined color="orange" label="Класс" :items="classTypes" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-btn @click="getReceipt" :loading="isDownload" color="orange" :disabled="isFormValid"
          style="width: 100%; height: 3.3rem">
          Получить
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { downloadFile } from "@/mixins/utils.js";
import FileInput from "@/components/UI/FileInput.vue";
export default {
  components: {
    FileInput,
  },
  data() {
    return {
      numberTicket: "",
      markup: "",
      subClass: 0,
      isDownload: false,
      isFull: true,
      classTypes: [
        { text: "Эконом", value: 0 },
        { text: "Бизнес", value: 1 },
      ],
    };
  },
  methods: {
    async getReceipt() {
      this.isDownload = true;
      try {
        let resp = await this.$axios.post(this.$enums.Endpoints.Admin.Receipt.FullExternal, {
          number: this.numberTicket,
          markup: this.markup,
          subClass: this.subClass,
          isFull: this.isFull,
        });
        let uint8Array = new Uint8Array(
          atob(resp.data)
            .split("")
            .map(function (c) {
              return c.charCodeAt(0);
            })
        );
        downloadFile(uint8Array, { name: `${this.numberTicket}_receipt.pdf` });
      } catch (e) {
        console.error(e.response ? e.response.data : e);
      } finally {
        this.isDownload = false;
      }
    },
  },
  computed: {
    isFormValid() {
      return this.numberTicket.length < 4 || this.markup.length === 0;
    },
  },
};
</script>

<style scoped></style>
